export default [
  {
    icon: 'mdi-view-dashboard',
    title: 'Dashboard',
    to: '/admin/dashboard',
  },
  {
    icon: 'mdi-account-box',
    title: 'Consultor dashboard',
    to: 'dashboardConsultor',
  },
  {
    icon: 'mdi-factory',
    title: 'Agroindústrias',
    to: '/admin/agroindustrias',
  },
  {
    icon: 'mdi-cash',
    title: 'Cronograma Financeiro',
    group: '/admin',
    children: [
      {
        icon: 'mdi-account-hard-hat',
        title: 'Cronograma Financeiro',
        to: 'financeiro',
      },
      {
        icon: 'mdi-account-hard-hat',
        title: 'Cronograma por consultor',
        to: 'financeiros',
      },
      {
        icon: 'mdi-car',
        title: 'Lançamento de percurso',
        to: 'odometer',
      },
    ],
  },
  {
    icon: 'mdi-account-multiple',
    title: 'Pessoas',
    group: '/admin',
    children: [
      {
        icon: 'mdi-account-hard-hat',
        title: 'Consultores',
        to: 'consultores',
      },
      {
        icon: 'mdi-account-star',
        title: 'Consultoria',
        to: 'consultancy',
      },
      {
        icon: 'mdi-account-cog',
        title: 'Supervisor',
        to: 'central',
      },
      {
        icon: 'mdi-home-flood',
        title: 'Fazendas',
        to: 'fazendas',
      },
      {
        icon: 'mdi-account-tie',
        title: 'Gestores',
        to: 'gestores',
      },
      {
        icon: 'mdi-account',
        title: 'Prestadores',
        to: 'prestador',
      },
      {
        icon: 'mdi-account-cowboy-hat',
        title: 'Produtores',
        to: 'produtores',
      },
    ],
  },
  {
    icon: 'mdi-calendar-month',
    title: 'Agenda',
    to: '/admin/schedules',
  },
  {
    icon: 'mdi-google-analytics',
    title: 'Projetos',
    to: '/admin/projects',
  },
  {
    icon: 'mdi-clipboard-list',
    title: 'Relatórios',
    group: '/admin/relatorios',
    children: [
      {
        subicon: 'mdi-clipboard-list',
        title: 'Visitas',
        group: '/admin/relatorios',
        children: [
          {
            icon: 'mdi-clipboard-list',
            title: 'Visita Geral',
            to: 'geral',
          },
          {
            icon: 'mdi-clipboard-text',
            title: 'Visita de CCS',
            to: 'ccs',
          },
          {
            icon: 'mdi-clipboard-edit',
            title: 'Visita de CPP',
            to: 'cpp',
          },
          {
            icon: 'mdi-clipboard-pulse',
            title: 'Visita Evolui Leite',
            to: 'evoluileite',
          },
          {
            icon: 'mdi-clipboard-text-multiple',
            title: 'Visita de Boas Práticas',
            to: 'boaspraticas',
          },
          {
            icon: 'mdi-clipboard-file',
            title: 'Visita de Resíduos de ATB',
            to: 'atb',
          },
          {
            icon: 'mdi-clipboard-text-play',
            title: 'Visita de Fraudes',
            to: 'fraudes',
          },
          {
            icon: 'mdi-clipboard-file',
            title: 'Visita Bonolat',
            to: 'bonolat',
          },
          {
            icon: 'mdi-clipboard-multiple',
            title: 'Visita Educampo',
            to: 'educampo',
          },
          {
            icon: 'mdi-clipboard-list',
            title: 'Visita relatório de embriões',
            to: 'embrioes',
          },
          {
            icon: 'mdi-clipboard-text',
            title: 'Visita Lactaleite',
            to: 'lactaleite',
          },
          {
            icon: 'mdi-clipboard-pulse',
            title: 'Relatório Sólidos',
            to: 'solidos',
          },
          {
            icon: 'mdi-clipboard-text-multiple',
            title: 'Relatório Promilat',
            to: 'promilat',
          },
          {
            icon: 'mdi-clipboard-list',
            title: 'Diagnóstico Geral',
            to: 'ordenha',
          },
          {
            icon: 'mdi-clipboard-multiple',
            title: 'Entregas de Material',
            to: 'entregas',
          },
        ],
      },
      {
        title: 'Gerenciais',
        group: '/admin/relatorios',
        children: [
          {
            icon: 'mdi-file-document-edit',
            title: 'Visitas por Consultor',
            to: 'visitas',
          },
          {
            icon: 'mdi-file-document-edit',
            title: 'Entrega de Material',
            to: 'entrega',
          },
          {
            icon: 'mdi-clipboard-list',
            title: 'Deslocamento de Consultores',
            to: 'deslocamento',
          },
          {
            icon: 'mdi-clipboard-list',
            title: 'Sincronização de Visitas',
            to: 'sinc',
          },
          {
            icon: 'mdi-home-search',
            title: 'Localização das Propriedades',
            to: 'localizacao',
          },
          {
            icon: 'mdi-home-map-marker',
            title: 'Distribuição de fazendas',
            to: 'farmDistribution',
          },
          {
            icon: 'mdi-folder-plus',
            title: 'Relatórios',
            to: 'configuracoes',
          },
        ],
      },
    ],
  },
]
